type ButtonProps = {
  title: string,
  link: string,
  icon: string,
  new_tab: boolean,
  is_icon: boolean,
  type: string,
  is_fit: boolean,
  class_name: string
}

const buttonType = (type: string) => {
  switch (type) {
    case 'primary_button':
      return "text-white bg-primary focus:ring-red-300"
    case 'secondary_button':
      return "text-white bg-secondary focus:ring-blue-300"
    case 'tertiary_button':
      return "text-white bg-tertiary focus:ring-gray-300"
    case 'ghost_button':
      return "text-tertiary bg-gray-300 focus:ring-gray-100"
    case 'menu_button':
      return "text-white hover:text-secondary hover:bg-white focus:ring-sky-100"
    default:
      return "text-secondary hover:underline focus:ring-0"
  }
}

export default function Button({title, link, icon='', new_tab=false, is_icon=false, type, is_fit=false, class_name=''}: ButtonProps) {
  return (
    <a className={`${buttonType(type)} ${is_fit ? 'w-fit' : 'w-auto'} ${class_name} flex items-center justify-center hover:opacity-90 focus:ring-4 focus:outline-none font-medium rounded-lg text-base px-5 py-2.5 text-center transition`}
      href={link}
      target={new_tab ? '_blank' : ''} rel="noopener noreferrer">
      {is_icon && <i className={`${icon} mr-2 text-inherit`}></i>}
      {title}
    </a>
  )
}